import type { RouteRecordNormalized } from '#vue-router'

type Crumb = {
  route: RouteRecordNormalized
  name: string | false
  path: string
  ariaCurrent: string
}

export const useCrumbs = () => {
  const crumbReplacement = useState<Record<string, string>>(
    'crumbReplacement',
    () => ({})
  )

  const replaceCrumb = (oldName: string, newName: string) => {
    crumbReplacement.value[oldName] = newName
  }

  const restoreCrumb = (oldName: string) => {
    delete crumbReplacement.value[oldName]
  }

  const route = useRoute()
  const router = useRouter()
  const { t } = useI18n()

  const routeNames = computed(() => {
    const nameParts = (route.name?.toString() || '').split('-')
    const names: string[] = []
    let name = ''

    nameParts.forEach((namePart) => {
      if (name === '') {
        name = namePart
      } else {
        name = `${name}-${namePart}`
      }

      names.push(name)
    })

    return names
  })

  const crumbRoutes = computed(() => {
    const routes: RouteRecordNormalized[] = []

    routeNames.value.forEach((name) => {
      const route = router.getRoutes().find((r) => r.name === name)

      if (route) {
        routes.push(route)
      }
    })

    return routes
  })

  const crumbPath = (crumbRoute: RouteRecordNormalized) => {
    const hasParams = crumbRoute.path.includes(':')

    if (hasParams) {
      // Replace param expressions with actual params from current route params
      let path = crumbRoute.path
      Object.entries(route.params).forEach(([paramName, paramValue]) => {
        paramValue = paramValue.toString()

        path = path.replace(`:${paramName}()`, paramValue)
      })

      return path
    } else {
      return crumbRoute.path
    }
  }

  const crumbText = (route: RouteRecordNormalized): string | false => {
    const routeMetaCrumb = route.meta.crumb

    if (routeMetaCrumb === false) return false

    if (!routeMetaCrumb) {
      if (typeof route.name !== 'string') {
        return false
      }
      return route.name
    }

    const name =
      crumbReplacement.value[routeMetaCrumb.name] ?? routeMetaCrumb.name

    if (routeMetaCrumb?.plural) {
      return t(name, 2)
    } else {
      return t(name)
    }
  }

  const ariaCurrent = (index: number) =>
    index === crumbRoutes.value.length - 1 ? 'page' : 'false'

  const crumbs = computed<Array<Crumb>>(() =>
    crumbRoutes.value
      .map((crumbRoute, index) => ({
        route: crumbRoute,
        name: crumbText(crumbRoute),
        path: crumbPath(crumbRoute),
        ariaCurrent: ariaCurrent(index),
      }))
      .filter((crumb) => crumb.name !== false)
  )

  return {
    crumbs,
    replaceCrumb,
    restoreCrumb,
  }
}
